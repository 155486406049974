<template>
    <link v-if="isDevMode" rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css"
        integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
    <link v-if="isDevMode" rel="stylesheet" href="https://www.destinymatrix.space/feex-template/css/destiny-matrix.css">
    <link v-if="isDevMode" rel="stylesheet" href="https://www.destinymatrix.space/feex-template/style.css">
    <link v-if="isDevMode" rel="stylesheet" href="https://www.destinymatrix.space/feex-template/css/matrix-calculator.css">
    
    <section>
        <div class="matrix-form-tabs d-flex">
            <button @click="changeToPers" data-type="single" id="isPersonal"
                :class="{ 'active': isPersonal, '': !isPersonal }" class="btn btn-light d-flex align-items-center mr-2">
                <svg class="icon mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                    fill="none">
                    <path
                        d="M10 0L12.6163 7.38371L20 10L12.6163 12.6163L10 20L7.38371 12.6163L0 10L7.38371 7.38371L10 0Z"
                        fill="#DDB591"></path>
                </svg>
                Personal
            </button>
            <button @click="changeToComp" data-type="couple" id="isCompatibility"
                :class="{ 'active': isCompatibility, '': !isCompatibility }"
                class="btn btn-light d-flex align-items-center">
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                    fill="none">
                    <path
                        d="M10 0L12.6163 7.38371L20 10L12.6163 12.6163L10 20L7.38371 12.6163L0 10L7.38371 7.38371L10 0Z"
                        fill="#DDB591"></path>
                </svg>
                <svg class="icon mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                    fill="none">
                    <path
                        d="M10 0L12.6163 7.38371L20 10L12.6163 12.6163L10 20L7.38371 12.6163L0 10L7.38371 7.38371L10 0Z"
                        fill="#DDB591"></path>
                </svg>
                Compatibility
            </button>
        </div>

        <div v-if="isCompatibility">
            <div class="matrix-form-tabs mt-4">

                <div class="input_data">
                    <div class="row">
                        <div class="col-3">
                            <select v-model="day" id="day" class="form-control">
                                <option v-for="n in 31" :key="n" :value="n">{{ n }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <select v-model="month" class="form-control">
                                <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                            </select>

                        </div>
                        <div class="col-6">
                            <select v-model="year" class="form-control">
                                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                            </select>

                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-3">
                            <select v-model="day_2" id="day" class="form-control">
                                <option v-for="n in 31" :key="n" :value="n">{{ n }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <select v-model="month_2" class="form-control">
                                <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <select v-model="year_2" class="form-control">
                                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                            </select>
                        </div>
                    </div>

                    <div class=" birthdate-item flex flex-col gap-2">
                        <button @click="Calculat" class="btn btn-gradient mt-3 d-flex justify-content-center" data-calculate-matrix>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                fill="none" class="mr-3">
                                <path
                                    d="M26.5567 3.25293H24.744V6.86732H21.3906V12.1082H23.2033V8.67452H26.6473L26.5567 3.25293Z"
                                    fill="white"></path>
                                <path
                                    d="M24.8338 10.6624H29.3654V12.4695H26.5558V15.8129H20.4834V14.0057H24.7431L24.8338 10.6624Z"
                                    fill="white"></path>
                                <path
                                    d="M10.6953 0.632568H12.508V4.06624H15.8614V9.48783H13.9581V5.87344H10.6953V0.632568Z"
                                    fill="white"></path>
                                <path
                                    d="M17.4931 7.22888H19.3964V12.5601H15.9523V14.9095H14.1396L14.2303 10.7529H17.4931V7.22888Z"
                                    fill="white"></path>
                                <path d="M12.5993 14.909H10.8773V12.4693H7.25195V10.5718H12.5993V14.909Z"
                                    fill="white"></path>
                                <path d="M15.9509 16.7168V18.524H12.5068V22.7709H10.6035V16.7168H15.9509Z"
                                    fill="white"></path>
                                <path d="M22.8386 19.1563V17.3491H19.2132V14.9998H17.4912V19.1563H22.8386Z"
                                    fill="white"></path>
                                <path
                                    d="M30.0001 13.9155H28.1874V17.5299H24.834V22.8611H26.6466V19.3371H30.0001V13.9155Z"
                                    fill="white"></path>
                                <path
                                    d="M26.7375 26.4755V24.6683H23.1122V20.5117H17.8555V22.4093H21.2995V26.4755H26.7375Z"
                                    fill="white"></path>
                                <path
                                    d="M17.4931 29.2768H19.3964V24.0359H15.9523V20.4215H14.1396V25.8431H17.4931V29.2768Z"
                                    fill="white"></path>
                                <path
                                    d="M10.6032 26.5656H7.15918V24.6681H12.5065V28.0114H16.0412V29.9993H10.6032V26.5656Z"
                                    fill="white"></path>
                                <path
                                    d="M5.25699 26.6558H3.44434V21.2342H6.79775V17.8005H8.70104V23.0414H5.25699V26.6558Z"
                                    fill="white"></path>
                                <path
                                    d="M9.6084 15.9934V14.0958H3.53599V17.4391H0.635742V19.3367H5.43928V15.9934H9.6084Z"
                                    fill="white"></path>
                                <path d="M5.52861 7.0481H3.53468V10.5721H0V15.9937H1.81266V12.3793H5.52861V7.0481Z"
                                    fill="white"></path>
                                <path
                                    d="M8.79325 3.34326H3.26465V5.24082H6.88996V9.30701H12.2373V7.40945H8.79325V3.34326Z"
                                    fill="white"></path>
                                <path d="M19.3962 0H14.0488V1.89756H17.6741V5.24087H22.9309V3.34331H19.3962V0Z"
                                    fill="white"></path>
                            </svg>
                            Calculate matrix
                        </button>
                    </div>
                    

                </div>

            </div>

            <div class="container" id="matrixResultCouple" data-result-block="couple">
                <!-- <div class=" w-full px-5 mt-12 lg:px-0"></div> -->
                <div class="row">

                    <div class="col-md-6">
                        <h2 class="chart-title mb-4">Compatibility</h2>
                        <div class="obch position-relative compatibility">
                            <img v-if="isDevMode" src="schemaenglish-4.svg" class="transimage">
                            <img v-if="!isDevMode" src="/feex-template/dist/schemaenglish-4.svg" class="transimage" />

                            <div id="n1" class="trans"
                                style="position:absolute; width:9%; height:9%; left:1.6%; top:44.9%;">

                                <div style="background:#a66db9; width:100%; height:100%; ">
                                    <span style=" color:#fff;" data-personal-calculation-position="a">{{ n1_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n3" class="trans"
                                style="position:absolute; width:9%; height:9%; left:45.5%; top:1.4%;">

                                <div style="background:#a66db9; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n3_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n5" class="trans"
                                style="position:absolute; width:9%; height:9%; left:89.1%; top:45.1%;">

                                <div style="background:#eb4b41; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n5_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n7" class="trans" style="position:absolute; width:9%; height:9%; left:45.4%; top:89%;">

                                <div style="background:#eb4b41; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n7_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n30" class="trans relative"
                                style="position:absolute; width:9%; height:9%; left:45%; top:45%;">

                                <div style="background:#ffde68; width:100%; height:100%;">
                                    <span data-personal-calculation-position="e">{{
                                        n30_3 }}</span>
                                </div>
                            </div>

                            <div id="n2" class="trans"
                                style="position:absolute; width:9%; height:9%; left:14.5%; top:14%;  border:3px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n2_3 }}</span>
                                </div>
                            </div>

                            <div id="n4" class="trans"
                                style="position:absolute; width:9%; height:9%; left:76%; top:14%;  border:3px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n4_3 }}</span>
                                </div>
                            </div>

                            <div id="n6" class="trans"
                                style="position:absolute; width:9%; height:9%; left:76.2%; top:76.4%;  border:3px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n6_3 }}</span>
                                </div>
                            </div>

                            <div id="n8" class="trans"
                                style="position:absolute; width:9%; height:9%; left:14.4%; top:76.1%;  border:3px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n8_3 }}</span>
                                </div>
                            </div>

                            <div id="n23" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.6%; top:76.7%; ">

                                <div style="background:#e98c46; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n15_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n21" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:77%; top:47.4%;  ">

                                <div style="background:#e98c46; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n21_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n28" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:61.3%; top:61%;  border:2px #000000  solid; background:#fff;">

                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n28_3 }}</span>
                                </div>
                            </div>

                            <div id="n29" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:55.5%; top:66.6%;  border:2px #000000  solid; background:#fff;   ">

                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n29_3 }}</span>
                                </div>
                            </div>

                            <div id="n17" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:18.3%; top:47.1%; ">

                                <div style="background:#80cabb; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n17_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n19" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.4%; top:17.8%;">

                                <div style="background:#80cabb; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n19_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n15" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:46.9%; top:82.2%;  border:2px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n23_3 }}</span>
                                </div>
                            </div>

                            <div id="n11" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:46.9%; top:11.4%; ">

                                <div style="background:#55a3b3; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n11_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n13" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:82.5%; top:46.9%;  border:2px #000000  solid; background:#fff;   ">

                                <div style="background:#fff; width:100%; height:100%; ">
                                    <span style=" ">{{ n13_3 }}</span>
                                </div>
                            </div>

                            <div id="n25" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:32%; top:47.3%;">
                                <div style="background:#63b062; width:100%; height:100%; ">
                                    <span style=" color:#fff">{{ n25_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n26" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.6%; top:31%;">
                                <div style="background:#63b062; width:100%; height:100%; ">
                                    <span style="color:#fff;">{{ n26_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n9" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:11.8%; top:46.5%;">

                                <div style="background:#55a3b3; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n9_3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n27" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:67%; top:55.2%;  border:2px #000000  solid; background:#fff;   ">
                                <div style="background:#fff; width:100%; height:100%; ">

                                    <span style="">{{ n27_3 }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5 offset-md-1 compatibility">
                        <!-- New table -->
                        <div class="div_matrix_tables">
                            <h2 id="--lang-caption_table_1">Health Chart</h2>
                            <div class="matrix_table">
                                <div id="--lang-physics" class="caption">Physics</div>
                                <div id="--lang-energy" class="caption">Energy</div>
                                <div id="--lang-emotions" class="caption">Emotions</div>
                                <div id="--lang-chakr" class="caption">Chakras</div>
                                
                                <div class="sahasrara sp" id="n33_3">{{ n33_3 }}</div>
                                <div class="sahasrara sen" id="n34_3">{{ n34_3 }}</div>
                                <div class="sahasrara sem" id="n35_3">{{ n35_3 }}</div>
                                <div class="sahasrara subcaption" id="--lang-chakr-sahasrara">Sahasrara</div>
                                
                                <div class="adjna adp" id="n36_3">{{ n36_3 }}</div>
                                <div class="adjna aden" id="n37_3">{{ n37_3 }}</div>
                                <div class="adjna adem" id="n38_3">{{ n38_3 }}</div>
                                <div class="adjna subcaption" id="--lang-chakr-adjna">Adjna</div>
                                
                                <div class="vishudha vp" id="n39_3">{{ n39_3 }}</div>
                                <div class="vishudha ven" id="n40_3">{{ n40_3 }}</div>
                                <div class="vishudha vem" id="n41_3">{{ n41_3 }}</div>
                                <div class="vishudha subcaption" id="--lang-chakr-vishudha">Vishudha</div>

                                <div class="anahata anp" id="n42_3">{{ n42_3 }}</div>
                                <div class="anahata anen" id="n43_3">{{ n43_3 }}</div>
                                <div class="anahata anem" id="n44_3">{{ n44_3 }}</div>
                                <div class="anahata subcaption" id="--lang-chakr-anahata">Anahata</div>

                                <div class="manipura map" id="n45_3">{{ n45_3 }}</div>
                                <div class="manipura maen" id="n46_3">{{ n46_3 }}</div>
                                <div class="manipura maem" id="n47_3">{{ n47_3 }}</div>
                                <div class="manipura subcaption" id="--lang-chakr-manipura">Manipura</div>

                                <div class="svadihstana svp" id="n48_3">{{ n48_3 }}</div>
                                <div class="svadihstana sven" id="n49_3">{{ n49_3 }}</div>
                                <div class="svadihstana svem" id="n50_3">{{ n50_3 }}</div>
                                <div class="svadihstana subcaption" id="--lang-chakr-svadihstana">Svadihstana</div>

                                <div class="muladhara mup" id="n51_3">{{ n51_3 }}</div>
                                <div class="muladhara muen" id="n52_3">{{ n52_3 }}</div>
                                <div class="muladhara muem" id="n53_3">{{ n53_3 }}</div>
                                <div class="muladhara subcaption" id="--lang-chakr-muladhara">Muladhara</div>

                                <div class="total tp" id="n54_3">{{ n54_3 }}</div>
                                <div class="total ten" id="n55_3">{{ n55_3 }}</div>
                                <div class="total tem" id="n56_3">{{ n56_3 }}</div>
                                <div class="total subcaption" id="--lang-chakr-total">Total</div>

                            </div>
                        </div>
                        <!-- New table end -->
                        <!-- <table class="table text-gray-900" width="100%">
                            <tbody>
                                <tr class="border-2 border-gray-700 p-3 h-14 font-bold" style="background:#C6C8D4;">
                                    <td></td>
                                    <td class="pl-4 explanation-item">Chakra</td>
                                    <td style="text-align:center"> Physic </td>
                                    <td style="text-align:center"> Energy </td>
                                    <td style="text-align:center"> Emotions </td>
                                </tr>
                                <tr class="border-2 border-gray-700  ">
                                    <td style="background:#993399; color:#fff;padding-left:5px;padding-right:5px">7</td>
                                    <td class="trans" id="chakra7"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Sahasrara</span><span
                                                    style="font-size:13px;text-align:left;">Mission</span> </div>
                                        </span></td>
                                    <td class="trans" id="n33">{{ n33_3 }}
                                    </td>
                                    <td class="trans" id="n34">{{ n34_3 }}
                                    </td>
                                    <td class="trans" id="n35">{{ n35_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#3366ff;color:#fff;padding-left:5px;padding-right:5px">6</td>
                                    <td class="trans" id="chakra6"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Ajna</span><span
                                                    style="font-size:13px;text-align:left;">Destination,
                                                    egregores</span></div>
                                        </span></td>
                                    <td class="trans" id="n36">{{ n36_3 }}
                                    </td>
                                    <td class="trans" id="n37">{{ n37_3 }}
                                    </td>
                                    <td class="trans" id="n38">{{ n38_3 }}
                                    </td>
                                </tr>

                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#33ccff; color:#fff;padding-left:5px;padding-right:5px">5</td>
                                    <td class="trans" id="chakra5"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Vishuddha</span><span
                                                    style="font-size:13px;text-align:left;">Destination,
                                                    egregores</span></div>
                                        </span></td>
                                    <td class="trans" id="n39">{{ n39_3 }}
                                    </td>
                                    <td class="trans" id="n40">{{ n40_3 }}
                                    </td>
                                    <td class="trans" id="n41">{{ n41_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#66cc66;color:#fff;padding-left:5px;padding-right:5px">4</td>
                                    <td class="trans" id="chakra4"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Anahata</span><span
                                                    style="font-size:13px;text-align:left;">Relationships,
                                                    worldview</span></div>
                                        </span></td>
                                    <td class="trans" id="n42">{{ n42_3 }}
                                    </td>
                                    <td class="trans" id="n43">{{ n43_3 }}
                                    </td>
                                    <td class="trans" id="n44">{{ n44_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#ffff99;padding-left:5px;padding-right:5px">3</td>
                                    <td class="trans" id="chakra3"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Manipura</span><span
                                                    style="font-size:13px;text-align:left;">Status, possession</span>
                                            </div>
                                        </span></td>
                                    <td class="trans" id="n45">{{ n45_3 }}
                                    </td>
                                    <td class="trans" id="n46">{{ n46_3 }}
                                    </td>
                                    <td class="trans" id="n47">{{ n47_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#ff9900;color:#fff;padding-left:5px;padding-right:5px">2</td>
                                    <td class="trans" id="chakra2"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Svadhisthana</span><span
                                                    style="font-size:13px;text-align:left;">Love and childlike
                                                    joy</span></div>
                                        </span></td>
                                    <td class="trans " id="n48">{{ n48_3 }}
                                    </td>
                                    <td class="trans" id="n49">{{ n49_3 }}
                                    </td>
                                    <td class="trans" id="n50">{{ n50_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#cc3333;color:#fff;padding-left:5px;padding-right:5px">1</td>
                                    <td class="trans" id="chakra1"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Muladhara</span><span
                                                    style="font-size:13px;text-align:left;">Body, matter</span></div>
                                        </span></td>
                                    <td class="trans" id="n51">{{ n51_3 }}
                                    </td>
                                    <td class="trans" id="n52">{{ n52_3 }}
                                    </td>
                                    <td class="trans" id="n53">{{ n53_3 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-2 pb-4 h-14" style="background:#C6C8D4;">
                                    <td></td>
                                    <td class="trans" id="chakra"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div
                                                style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">
                                                    Total</span><span style="font-size:13px;text-align:left;">General
                                                    energy field</span></div>
                                        </span></td>
                                    <td class="trans" id="n54">{{ n54_3 }}
                                    </td>
                                    <td class="trans" id="n55">{{ n55_3 }}
                                    </td>
                                    <td class="trans" id="n56">{{ n56_3 }}
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                    </div>
                </div>
                <div class="section-with-diagram__bottom-values-row row mt-5" data-matrix-footer-single>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Soul searching</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Mix of male and female. Building relationships. Skills.</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="small-diagram-values__titles-block">
                                    <div class="h6 small-diagram-values__title">Sky</div>
                                    <div class="h6 small-diagram-values__title">Earth</div>
                                </div>
                                <div class="small-diagram-values__values-block">
                                    <div class="small-diagram-values__path-img">
                                        <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0V2H18V46H0V48H20V25H38V23H20V0H0Z" fill="#222831"></path>
                                        </svg>
                                    </div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -top-left diagram-value -active" data-personal-calculation-position="h">{{ n57_3 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -bottom-left diagram-value -active" data-personal-calculation-position="j">{{ n59_3 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -center diagram-value -active" data-personal-calculation-position="m">{{ n58_3 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Socialization</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Social and family systems. Result and public acceptance.</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="small-diagram-values__titles-block">
                                    <div class="h6 small-diagram-values__title">M</div>
                                    <div class="h6 small-diagram-values__title">F</div>
                                </div>
                                <div class="small-diagram-values__values-block">
                                    <div class="small-diagram-values__path-img">
                                        <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0V2H18V46H0V48H20V25H38V23H20V0H0Z" fill="#222831"></path>
                                        </svg>
                                    </div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -top-left diagram-value -active" data-personal-calculation-position="n">{{ n60_3 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -bottom-left diagram-value -active" data-personal-calculation-position="t">{{ n62_3 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -center diagram-value -active" data-personal-calculation-position="z">{{ n61_3 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Spiritual knowledge</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Spiritual exam. Who am I for the God? Do I have anything spiritual inside?</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="diagram-value js-personal-calculation-item -active" data-personal-calculation-position="s">{{ n63_3 }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Personal -->
        <div v-if="isPersonal">
            <div
                class="matrix-form-tabs mt-4">

                    <div class="input_data">

                        <div class="row">
                            <div class="col-12">
                                <input type="text" class="form-control mb-3" v-model="name" placeholder="Your Name">
                            </div>
                            <div class="col-3">
                                <select v-model="day" id="personalDay" class="form-control">
                                    <option v-for="n in 31" :key="n" :value="n">{{ n }}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <select v-model="month" id="personalMonth" class="form-control">
                                    <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <select v-model="year" id="personalYear" class="form-control">
                                    <option :value="year">{{ year }}</option>
                                    <option v-for="(year, i) in years" :key="i" :value="year">{{ year }}</option>
                                </select>
                            </div>
                        </div>
                        <div class=" birthdate-item flex flex-col gap-2">
                            <button @click="Calculat" class="btn btn-gradient mt-3 d-flex justify-content-center" data-calculate-matrix>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                    fill="none" class="mr-3">
                                    <path
                                        d="M26.5567 3.25293H24.744V6.86732H21.3906V12.1082H23.2033V8.67452H26.6473L26.5567 3.25293Z"
                                        fill="white"></path>
                                    <path
                                        d="M24.8338 10.6624H29.3654V12.4695H26.5558V15.8129H20.4834V14.0057H24.7431L24.8338 10.6624Z"
                                        fill="white"></path>
                                    <path
                                        d="M10.6953 0.632568H12.508V4.06624H15.8614V9.48783H13.9581V5.87344H10.6953V0.632568Z"
                                        fill="white"></path>
                                    <path
                                        d="M17.4931 7.22888H19.3964V12.5601H15.9523V14.9095H14.1396L14.2303 10.7529H17.4931V7.22888Z"
                                        fill="white"></path>
                                    <path d="M12.5993 14.909H10.8773V12.4693H7.25195V10.5718H12.5993V14.909Z"
                                        fill="white"></path>
                                    <path d="M15.9509 16.7168V18.524H12.5068V22.7709H10.6035V16.7168H15.9509Z"
                                        fill="white"></path>
                                    <path d="M22.8386 19.1563V17.3491H19.2132V14.9998H17.4912V19.1563H22.8386Z"
                                        fill="white"></path>
                                    <path
                                        d="M30.0001 13.9155H28.1874V17.5299H24.834V22.8611H26.6466V19.3371H30.0001V13.9155Z"
                                        fill="white"></path>
                                    <path
                                        d="M26.7375 26.4755V24.6683H23.1122V20.5117H17.8555V22.4093H21.2995V26.4755H26.7375Z"
                                        fill="white"></path>
                                    <path
                                        d="M17.4931 29.2768H19.3964V24.0359H15.9523V20.4215H14.1396V25.8431H17.4931V29.2768Z"
                                        fill="white"></path>
                                    <path
                                        d="M10.6032 26.5656H7.15918V24.6681H12.5065V28.0114H16.0412V29.9993H10.6032V26.5656Z"
                                        fill="white"></path>
                                    <path
                                        d="M5.25699 26.6558H3.44434V21.2342H6.79775V17.8005H8.70104V23.0414H5.25699V26.6558Z"
                                        fill="white"></path>
                                    <path
                                        d="M9.6084 15.9934V14.0958H3.53599V17.4391H0.635742V19.3367H5.43928V15.9934H9.6084Z"
                                        fill="white"></path>
                                    <path d="M5.52861 7.0481H3.53468V10.5721H0V15.9937H1.81266V12.3793H5.52861V7.0481Z"
                                        fill="white"></path>
                                    <path
                                        d="M8.79325 3.34326H3.26465V5.24082H6.88996V9.30701H12.2373V7.40945H8.79325V3.34326Z"
                                        fill="white"></path>
                                    <path d="M19.3962 0H14.0488V1.89756H17.6741V5.24087H22.9309V3.34331H19.3962V0Z"
                                        fill="white"></path>
                                </svg>
                                Calculate matrix
                            </button>
                        </div>
                    </div>

            </div>

            <div class="container" id="matrixResultSingle" data-result-block="single">
                <div class="row">
                    <div class="obch position-relative col-md-6">
                        <h2 class="chart-title mb-4">{{name}}&nbsp;</h2>
                        <div id="matrix" class="position-relative">
                            <img v-if="isDevMode" src="schemaenglish-4.svg" class="transimage" />
                            <img v-if="!isDevMode" src="/feex-template/dist/schemaenglish-4.svg" class="transimage" />

                            <div id="n1" class="trans"
                                style="position:absolute; width:9%; height:9%; left:1.6%; top:45.1%;">
                                <div style="background:#a66db9; width:100%; height:100%; ">
                                    <span style=" color:#fff;" data-personal-calculation-position="a">{{ n1
                                        }}</span>
                                </div>
                            </div>

                            <div id="n3" class="trans"
                                style="position:absolute; width:9%; height:9%; left:45.5%; top:1.4%;">

                                <div style="background:#a66db9; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n3
                                        }}</span>
                                </div>
                            </div>

                            <div id="n5" class="trans"
                                style="position:absolute; width:9%; height:9%; left:89.1%; top:45.1%;">

                                <div style="background:#eb4b41; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n5
                                        }}</span>
                                </div>
                            </div>

                            <div id="n7" class="trans" style="position:absolute; width:9%; height:9%; left:45.4%; top:89%;">

                                <div style="background:#eb4b41; width:100%; height:100%;  ">
                                    <span style=" color:#fff;">{{ n7
                                        }}</span>
                                </div>
                            </div>

                            <div id="n30" class="trans relative"
                                style="position:absolute; width:9%; height:9%; left:45%; top:45%;">

                                <div style="background:#ffde68; width:100%; height:100%;">
                                    <span data-personal-calculation-position="e">{{
                                        n30 }}</span>
                                </div>
                            </div>

                            <div id="n2" class="trans"
                                style="position:absolute; width:9%; height:9%; left:14.5%; top:14%;  border:3px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n2 }}</span>
                                </div>
                            </div>

                            <div id="n4" class="trans"
                                style="position:absolute; width:9%; height:9%; left:76%; top:14%;  border:3px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n4 }}</span>
                                </div>
                            </div>

                            <div id="n6" class="trans"
                                style="position:absolute; width:9%; height:9%; left:76.2%; top:76.4%;  border:3px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n6 }}</span>
                                </div>
                            </div>

                            <div id="n8" class="trans"
                                style="position:absolute; width:9%; height:9%; left:14.4%; top:76.1%;  border:3px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n8 }}</span>
                                </div>
                            </div>

                            <div id="n23" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.5%; top:76.5%; ">

                                <div style="background:#e98c46; width:100%; height:100%; ">
                                    <span style="color:#fff;">{{ n23
                                        }}</span>
                                </div>
                            </div>

                            <div id="n21" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:77%; top:47.3%;  ">

                                <div style="background:#e98c46; width:100%; height:100%; ">
                                    <span style="color:#fff;">{{ n21
                                        }}</span>
                                </div>
                            </div>

                            <div id="n28" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:61%; top:60.5%;  border:2px #000000  solid; background:#f9ecde;">

                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n28 }}</span>
                                </div>
                            </div>

                            <div id="n29" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:58.5%; top:66.5%;  border:2px #000000  solid; background:#f9ecde;   ">

                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n29 }}</span>
                                </div>
                            </div>

                            <div id="n17" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:18.1%; top:47.2%; ">

                                <div style="background:#80cabb; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n17
                                        }}</span>
                                </div>
                            </div>

                            <div id="n19" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.4%; top:18%;">

                                <div style="background:#80cabb; width:100%; height:100%; ">
                                    <span style="color:#fff;">{{ n19
                                        }}</span>
                                </div>
                            </div>

                            <div id="n15" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:46.9%; top:82.2%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n15 }}</span>
                                </div>
                            </div>

                            <div id="n11" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:46.9%; top:11.5%; ">

                                <div style="background:#55a3b3; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n11
                                        }}</span>
                                </div>
                            </div>

                            <div id="n13" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:82.5%; top:46.7%;  border:2px #000000  solid; background:#f9ecde;   ">

                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n13 }}</span>
                                </div>
                            </div>

                            <div id="n25" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:32%; top:47.3%;">
                                <div style="background:#63b062; width:100%; height:100%; ">
                                    <span style=" color:#fff">{{ n25
                                        }}</span>
                                </div>
                            </div>

                            <div id="n26" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:47.6%; top:31%;">
                                <div style="background:#63b062; width:100%; height:100%; ">
                                    <span style="color:#fff;">{{ n26
                                        }}</span>
                                </div>
                            </div>

                            <div id="n9" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:11.5%; top:46.7%;">

                                <div style="background:#55a3b3; width:100%; height:100%; ">
                                    <span style=" color:#fff;">{{ n9
                                        }}</span>
                                </div>
                            </div>

                            <div id="n18" class="trans"
                                style="position: absolute; width: 4.5%; height: 4.5%; left: 26.4%; top: 26.6%; border: 2px solid rgb(0, 0, 0); background: rgb(249, 236, 222);">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n18 }}</span>
                                </div>
                            </div>

                            <div id="n10" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:21.9%; top:21.9%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n10 }}</span>
                                </div>
                            </div>

                            <div id="n20" class="trans"
                                style="position: absolute; width: 4.5%; height: 4.5%; left: 68.8%; top: 26.6%; border: 2px solid rgb(0, 0, 0); background: rgb(249, 236, 222);">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n20 }}</span>
                                </div>
                            </div>

                            <div id="n12" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:72.5%; top:21.9%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n12 }}</span>
                                </div>
                            </div>

                            <div id="n22" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:68.5%; top:68%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n22 }}</span>
                                </div>
                            </div>

                            <div id="n14" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:72%; top:71.7%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n14 }}</span>
                                </div>
                            </div>

                            <div id="n24" class="trans"
                                style="position: absolute; width: 4.5%; height: 4.5%; left: 26.5%; top: 68%; border: 2px solid rgb(0, 0, 0); background: rgb(249, 236, 222);">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n24 }}</span>
                                </div>
                            </div>

                            <div id="n16" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:21.9%; top:71.4%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">
                                    <span style="">{{ n16 }}</span>
                                </div>
                            </div>

                            <div id="n27" class="trans"
                                style="position:absolute; width:4.5%; height:4.5%; left:67%; top:58.5%;  border:2px #000000  solid; background:#f9ecde;   ">
                                <div style="background:#f9ecde; width:100%; height:100%; ">

                                    <span style="">{{ n27 }}</span>
                                </div>
                            </div>


                            <div id="n32" class="trans"
                                style="position:absolute; width:5.5%; height:5.5%; left:61%; top:46.9%;  border:2px #000000  solid; background:#FFFFFF;    ">
                                <div style=" width:100%; height:100%;  "><span style=""> {{ n32 }} </span></div>
                            </div>


                            <div id="n31" class="trans"
                                style="position:absolute; width:6.5%; height:6.5%; left:54.3%; top:46.3%;  border:2px #000000  solid; background:#FFFFFF;    ">
                                <div style=" width:100%; height:100%;  ">
                                    <span style=" ">{{ n31 }}</span>
                                </div>
                            </div>



                            <!-- 0 - 10 -->
                            <div class="trans" style="position:absolute; top:30.3%; left:3.5%; height:3%; width:5%;">
                                <span style="">{{ y1 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:36.5%; left:1%; height:3%; width:5%;">
                                <span style="">{{ y2 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:39.5%; left:0%; height:3%; width:5%;">
                                <span style="">{{ y3 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:33.5%; left:2%; height:3%; width:5%;">
                                <span style="">{{ y4 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:24.3%; left:6.2%; height:3%; width:5%;">
                                <span style="">{{ y5 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:21.1%; left:7.6%; height:3%; width:5%;">
                                <span style="">{{ y6 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:27.3%; left:4.7%; height:3%; width:5%;">
                                <span style="">{{ y7 }}</span>
                            </div>
                            <!-- 10 - 20 -->
                            <div class="trans" style="position:absolute; top:3.9%; left:29.7%; height:3%; width:5%;">
                                <span style="">{{ y8 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:6.8%; left:23.5%; height:3%; width:5%;">
                                <span style="">{{ y9 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:8.3%; left:20%; height:3%; width:5%;">
                                <span style="">{{ y10 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:5.1%; left:26.5%; height:3%; width:5%;">
                                <span style="">{{ y11 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:1.8%; left:36%; height:3%; width:5%;">
                                <span style="">{{ y12 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:0.7%; left:39.5%; height:3%; width:5%;">
                                <span style="">{{ y13 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:2.6%; left:33%; height:3%; width:5%;">
                                <span style="">{{ y14 }}</span>
                            </div>
                            <!-- 20 - 30 -->
                            <div class="trans" style="position:absolute; top:4.1%; left:65.6%; height:3%; width:5%;">
                                <span style="">{{ y15 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:1.9%; left:59.7%; height:3%; width:5%;">
                                <span style="">{{ y16 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:0.5%; left:55.3%; height:3%; width:5%;">
                                <span style="">{{ y17 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:3.1%; left:63%; height:3%; width:5%;">
                                <span style="">{{ y18 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:6.6%; left:71.3%; height:3%; width:5%;">
                                <span style="">{{ y19 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:5.5%; left:68.5%; height:3%; width:5%;">
                                <span style="">{{ y20 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:8.1%; left:74.6%; height:3%; width:5%;">
                                <span style="">{{ y21 }}</span>
                            </div>
                            <!-- 30 - 40 -->
                            <div class="trans" style="position:absolute; top:29.4%; left:91.6%; height:3%; width:5%;">
                                <span style="">{{ y22 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:23.3%; left:89.0%; height:3%; width:5%;">
                                <span style="">{{ y23 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:20.1%; left:87.2%; height:3%; width:5%;">
                                <span style="">{{ y24 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:26.1%; left:90.0%; height:3%; width:5%;">
                                <span style="">{{ y25 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:35.5%; left:94%; height:3%; width:5%;">
                                <span style="">{{ y26 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:39.2%; left:95.3%; height:3%; width:5%;">
                                <span style="">{{ y27 }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:32.5%; left:92.6%; height:3%; width:5%;">
                                <span style="">{{ y28 }}</span>
                            </div>
                            <!-- 40 - 50 -->
                            <!-- 40 - 50 -->
                            <div class="trans" style="position:absolute; top:65.2%; left:91.7%; height:3%; width:5%;">
                                <span style="" id="y29">{{ y29
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:59.2%; left:93.7%; height:3%; width:5%;">
                                <span style="" id="y30">{{ y30
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:62.2%; left:92.7%; height:3%; width:5%;">
                                <span style="" id="y31">{{ y31
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:55.8%; left:95.2%; height:3%; width:5%;">
                                <span style="" id="y32">{{ y32
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:71.7%; left:88.2%; height:3%; width:5%;">
                                <span style="" id="y33">{{ y33
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:74.6%; left:87.2%; height:3%; width:5%;">
                                <span style="" id="y34">{{ y34
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:68.5%; left:90.2%; height:3%; width:5%;">
                                <span style="" id="y35">{{ y35
                                    }}</span>
                            </div>
                            <!-- 50 - 60 -->
                            <div class="trans" style="position:absolute; top:92.2%; left:65%; height:3%; width:5%;">
                                <span style="" id="y36">{{ y36
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:89.2%; left:71.7%; height:3%; width:5%;">
                                <span style="" id="y37">{{ y37
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:88.2%; left:75.0%; height:3%; width:5%;">
                                <span style="" id="y38">{{ y38
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:90.8%; left:68.7%; height:3%; width:5%;">
                                <span style="" id="y39">{{ y39
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:94.8%; left:59%; height:3%; width:5%;">
                                <span style="" id="y40">{{ y40
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:93.9%; left:62%; height:3%; width:5%;">
                                <span style="" id="y41">{{ y41
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:96.2%; left:55%; height:3%; width:5%;">
                                <span style="" id="y42">{{ y42
                                    }}</span>
                            </div>
                            <!-- 60 - 70 -->
                            <div class="trans" style="position:absolute; top:92.5%; left:30%; height:3%; width:5%;">
                                <span style="" id="y43">{{ y43
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:94.7%; left:36.5%; height:3%; width:5%;">
                                <span style="" id="y44">{{ y44
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:96.2%; left:39.5%; height:3%; width:5%;">
                                <span style="" id="y45">{{ y45
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:93.7%; left:33.3%; height:3%; width:5%;">
                                <span style="" id="y46">{{ y46
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:89.5%; left:23.5%; height:3%; width:5%;">
                                <span style="" id="y47">{{ y47
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:91.1%; left:26.5%; height:3%; width:5%;">
                                <span style="" id="y48">{{ y48
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:88.4%; left:20.5%; height:3%; width:5%;">
                                <span style="" id="y49">{{ y49
                                    }}</span>
                            </div>
                            <!-- 70 - 80 -->
                            <div class="trans" style="position:absolute; top:65.3%; left:2.8%; height:3%; width:5%;">
                                <span style="" id="y50">{{ y50
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:72%; left:5.8%; height:3%; width:5%;">
                                <span style="" id="y51">{{ y51
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:75%; left:7.1%; height:3%; width:5%;">
                                <span style="" id="y52">{{ y52
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:68.5%; left:4.8%; height:3%; width:5%;">
                                <span style="" id="y53">{{ y53
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:59%; left:0.6%; height:3%; width:5%;">
                                <span style="" id="y54">{{ y54
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:56%; left:-0.8%; height:3%; width:5%;">
                                <span style="" id="y55">{{ y55
                                    }}</span>
                            </div>
                            <div class="trans" style="position:absolute; top:62%; left:1.8%; height:3%; width:5%;">
                                <span style="" id="y56">{{ y56
                                    }}</span>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-5 offset-md-1">
                        <!-- New table -->
                        <div class="div_matrix_tables">
                            <h2 id="--lang-caption_table_1">Health Chart</h2>
                            <div class="matrix_table">
                                <div id="--lang-physics" class="caption">Physics</div>
                                <div id="--lang-energy" class="caption">Energy</div>
                                <div id="--lang-emotions" class="caption">Emotions</div>
                                <div id="--lang-chakr" class="caption">Chakras</div>
                                
                                <div class="sahasrara sp" id="n33">{{ n33 }}</div>
                                <div class="sahasrara sen" id="n34">{{ n34 }}</div>
                                <div class="sahasrara sem" id="n35">{{ n35 }}</div>
                                <div class="sahasrara subcaption" id="--lang-chakr-sahasrara">Sahasrara</div>
                                
                                <div class="adjna adp" id="n36">{{ n36 }}</div>
                                <div class="adjna aden" id="n37">{{ n37 }}</div>
                                <div class="adjna adem" id="n38">{{ n38 }}</div>
                                <div class="adjna subcaption" id="--lang-chakr-adjna">Adjna</div>
                                
                                <div class="vishudha vp" id="n39">{{ n39 }}</div>
                                <div class="vishudha ven" id="n40">{{ n40 }}</div>
                                <div class="vishudha vem" id="n41">{{ n41 }}</div>
                                <div class="vishudha subcaption" id="--lang-chakr-vishudha">Vishudha</div>

                                <div class="anahata anp" id="n42">{{ n42 }}</div>
                                <div class="anahata anen" id="n43">{{ n43 }}</div>
                                <div class="anahata anem" id="n44">{{ n44 }}</div>
                                <div class="anahata subcaption" id="--lang-chakr-anahata">Anahata</div>

                                <div class="manipura map" id="n45">{{ n45 }}</div>
                                <div class="manipura maen" id="n46">{{ n46 }}</div>
                                <div class="manipura maem" id="n47">{{ n47 }}</div>
                                <div class="manipura subcaption" id="--lang-chakr-manipura">Manipura</div>

                                <div class="svadihstana svp" id="n48">{{ n48 }}</div>
                                <div class="svadihstana sven" id="n49">{{ n49 }}</div>
                                <div class="svadihstana svem" id="n50">{{ n50 }}</div>
                                <div class="svadihstana subcaption" id="--lang-chakr-svadihstana">Svadihstana</div>

                                <div class="muladhara mup" id="n51">{{ n51 }}</div>
                                <div class="muladhara muen" id="n52">{{ n52 }}</div>
                                <div class="muladhara muem" id="n53">{{ n53 }}</div>
                                <div class="muladhara subcaption" id="--lang-chakr-muladhara">Muladhara</div>

                                <div class="total tp" id="n54">{{ n54 }}</div>
                                <div class="total ten" id="n55">{{ n55 }}</div>
                                <div class="total tem" id="n56">{{ n56 }}</div>
                                <div class="total subcaption" id="--lang-chakr-total">Total</div>

                            </div>
                        </div>
                        <!-- New table end -->
                        <!-- <h3 class="font-bold text-2xl mb-3 text-center lg:text-left"> Health Chart </h3> -->

                        <!-- <table class="table text-gray-900" width="100%">
                            <tbody>
                                <tr class="border-2 border-gray-700 p-3 h-14 font-bold" style="background:#C6C8D4;">
                                    <td></td>
                                    <td class="pl-4 explanation-item">Chakra</td>
                                    <td style="text-align:center"> Physic </td>
                                    <td style="text-align:center"> Energy </td>
                                    <td style="text-align:center"> Emotions </td>
                                </tr>
                                <tr class="border-2 border-gray-700  ">
                                    <td style="background:#993399; color:#fff;padding-left:5px;padding-right:5px">7</td>
                                    <td class="trans" id="chakra7"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Sahasrara</span><span
                                                    style="font-size:13px;text-align:left;">Mission</span> </div>
                                        </span></td>
                                    <td class="trans" id="n33">{{ n33 }}
                                    </td>
                                    <td class="trans" id="n34">{{ n34 }}
                                    </td>
                                    <td class="trans" id="n35">{{ n35 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#3366ff;color:#fff;padding-left:5px;padding-right:5px">6</td>
                                    <td class="trans" id="chakra6"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Ajna</span><span
                                                    style="font-size:13px;text-align:left;">Destination, egregores</span>
                                            </div>
                                        </span></td>
                                    <td class="trans" id="n36">{{ n36 }}
                                    </td>
                                    <td class="trans" id="n37">{{ n37 }}
                                    </td>
                                    <td class="trans" id="n38">{{ n38 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#33ccff; color:#fff;padding-left:5px;padding-right:5px">5</td>
                                    <td class="trans" id="chakra5"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Vishuddha</span><span
                                                    style="font-size:13px;text-align:left;">Destination, egregores</span>
                                            </div>
                                        </span></td>
                                    <td class="trans" id="n39">{{ n39 }}
                                    </td>
                                    <td class="trans" id="n40">{{ n40 }}
                                    </td>
                                    <td class="trans" id="n41">{{ n41 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#66cc66;color:#fff;padding-left:5px;padding-right:5px">4</td>
                                    <td class="trans" id="chakra4"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Anahata</span><span
                                                    style="font-size:13px;text-align:left;">Relationships, worldview</span>
                                            </div>
                                        </span></td>
                                    <td class="trans" id="n42">{{ n42 }}
                                    </td>
                                    <td class="trans" id="n43">{{ n43 }}
                                    </td>
                                    <td class="trans" id="n44">{{ n44 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#ffff99;padding-left:5px;padding-right:5px">3</td>
                                    <td class="trans" id="chakra3"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Manipura</span><span
                                                    style="font-size:13px;text-align:left;">Status, possession</span></div>
                                        </span></td>
                                    <td class="trans" id="n45">{{ n45 }}
                                    </td>
                                    <td class="trans" id="n46">{{ n46 }}
                                    </td>
                                    <td class="trans" id="n47">{{ n47 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#ff9900;color:#fff;padding-left:5px;padding-right:5px">2</td>
                                    <td class="trans" id="chakra2"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Svadhisthana</span><span
                                                    style="font-size:13px;text-align:left;">Love and childlike joy</span>
                                            </div>
                                        </span></td>
                                    <td class="trans " id="n48">{{ n48 }}
                                    </td>
                                    <td class="trans" id="n49">{{ n49 }}
                                    </td>
                                    <td class="trans" id="n50">{{ n50 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-3">
                                    <td style="background:#cc3333;color:#fff;padding-left:5px;padding-right:5px">1</td>
                                    <td class="trans" id="chakra1"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">Muladhara</span><span
                                                    style="font-size:13px;text-align:left;">Body, matter</span></div>
                                        </span></td>
                                    <td class="trans" id="n51">{{ n51 }}
                                    </td>
                                    <td class="trans" id="n52">{{ n52 }}
                                    </td>
                                    <td class="trans" id="n53">{{ n53 }}
                                    </td>
                                </tr>
                                <tr class="border-2 border-gray-700 p-2 pb-4 h-14" style="background:#C6C8D4;">
                                    <td></td>
                                    <td class="trans" id="chakra"><span class="td_chakra p-3"
                                            style="display:flex;gap:15px;align-items:center;">
                                            <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;">
                                                <span style="font-size:16px;font-weight:bold">
                                                    Total</span><span style="font-size:13px;text-align:left;">General energy
                                                    field</span></div>
                                        </span></td>
                                    <td class="trans" id="n54">{{ n54 }}
                                    </td>
                                    <td class="trans" id="n55">{{ n55 }}
                                    </td>
                                    <td class="trans" id="n56">{{ n56 }}
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                    </div>

                </div>


                <div class="section-with-diagram__bottom-values-row row mt-5" data-matrix-footer-single>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Soul searching</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Mix of male and female. Building relationships. Skills.</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="small-diagram-values__titles-block">
                                    <div class="h6 small-diagram-values__title">Sky</div>
                                    <div class="h6 small-diagram-values__title">Earth</div>
                                </div>
                                <div class="small-diagram-values__values-block">
                                    <div class="small-diagram-values__path-img">
                                        <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0V2H18V46H0V48H20V25H38V23H20V0H0Z" fill="#222831"></path>
                                        </svg>
                                    </div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -top-left diagram-value -active" data-personal-calculation-position="h">{{ n57 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -bottom-left diagram-value -active" data-personal-calculation-position="j">{{ n59 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -center diagram-value -active" data-personal-calculation-position="m">{{ n58 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Socialization</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Social and family systems. Result and public acceptance.</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="small-diagram-values__titles-block">
                                    <div class="h6 small-diagram-values__title">M</div>
                                    <div class="h6 small-diagram-values__title">F</div>
                                </div>
                                <div class="small-diagram-values__values-block">
                                    <div class="small-diagram-values__path-img">
                                        <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0V2H18V46H0V48H20V25H38V23H20V0H0Z" fill="#222831"></path>
                                        </svg>
                                    </div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -top-left diagram-value -active" data-personal-calculation-position="n">{{ n60 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -bottom-left diagram-value -active" data-personal-calculation-position="t">{{ n62 }}</div>
                                    <div class="small-diagram-values__value js-personal-calculation-item -center diagram-value -active" data-personal-calculation-position="z">{{ n61 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="diagram-values-item">
                            <div class="h6 diagram-values-item__title">Spiritual knowledge</div>
                            <div class="diagram-values-item__description text-block">
                                <p>Spiritual exam. Who am I for the God? Do I have anything spiritual inside?</p>
                            </div>
                            <div class="small-diagram-values">
                                <div class="diagram-value js-personal-calculation-item -active" data-personal-calculation-position="s">{{ n63 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>


export default {
    data() {
        return {
            isDevMode: process.env.NODE_ENV === 'development',
            name: "",
            isPersonal: true,
            isCompatibility: false,
            currentYear: new Date().getFullYear(),
            calcCount: 0,

            values: 0,
            day: 1,
            day_2: 1,
            month: 1,
            month_2: 1,
            year: 1901,
            year_2: 1901,
            n1: 0,
            n1_2: 0,
            n1_3: 0,
            n3: 0,
            n3_2: 0,
            n3_3: 0,
            n5: 0,
            n5_2: 0,
            n5_3: 0,
            n2: 0,
            n2_2: 0,
            n2_3: 0,
            n4: 0,
            n4_2: 0,
            n4_3: 0,
            n6: 0,
            n6_2: 0,
            n6_3: 0,
            n7: 0,
            n7_2: 0,
            n7_3: 0,
            n8: 0,
            n8_2: 0,
            n8_3: 0,
            n9: 0,
            n9_2: 0,
            n9_3: 0,
            n10: 0,
            n10_2: 0,
            n10_3: 0,
            n11: 0,
            n11_2: 0,
            n11_3: 0,
            n12: 0,
            n12_2: 0,
            n12_3: 0,
            n13: 0,
            n13_2: 0,
            n13_3: 0,
            n14: 0,
            n14_2: 0,
            n14_3: 0,
            n15: 0,
            n15_2: 0,
            n15_3: 0,
            n16: 0,
            n16_2: 0,
            n16_3: 0,
            n17: 0,
            n17_2: 0,
            n17_3: 0,
            n18: 0,
            n18_2: 0,
            n18_3: 0,
            n19: 0,
            n19_2: 0,
            n19_3: 0,
            n20: 0,
            n20_2: 0,
            n20_3: 0,
            n21: 0,
            n21_2: 0,
            n21_3: 0,
            n22: 0,
            n22_2: 0,
            n22_3: 0,
            n23: 0,
            n23_2: 0,
            n23_3: 0,
            n24: 0,
            n24_2: 0,
            n24_3: 0,
            n25: 0,
            n25_2: 0,
            n25_3: 0,
            n26: 0,
            n26_2: 0,
            n26_3: 0,
            n27: 0,
            n27_2: 0,
            n27_3: 0,
            n28: 0,
            n28_2: 0,
            n28_3: 0,
            n29: 0,
            n29_2: 0,
            n29_3: 0,
            n30: 0,
            n30_2: 0,
            n30_3: 0,
            n31: 0,
            n31_2: 0,
            n31_3: 0,
            n32: 0,
            n32_2: 0,
            n32_3: 0,

            n33: 0,
            n34: 0,
            n35: 0,
            n36: 0,
            n37: 0,
            n38: 0,
            n39: 0,
            n40: 0,
            n41: 0,
            n42: 0,
            n43: 0,
            n44: 0,
            n45: 0,
            n46: 0,
            n47: 0,
            n48: 0,
            n49: 0,
            n50: 0,
            n51: 0,
            n52: 0,
            n53: 0,
            n54: 0,
            n55: 0,
            n56: 0,
            n57: 0,
            n58: 0,
            n59: 0,
            n60: 0,
            n61: 0,
            n62: 0,
            n63: 0,

            n33_2: 0,
            n34_2: 0,
            n35_2: 0,
            n36_2: 0,
            n37_2: 0,
            n38_2: 0,
            n39_2: 0,
            n40_2: 0,
            n41_2: 0,
            n42_2: 0,
            n43_2: 0,
            n44_2: 0,
            n45_2: 0,
            n46_2: 0,
            n47_2: 0,
            n48_2: 0,
            n49_2: 0,
            n50_2: 0,
            n51_2: 0,
            n52_2: 0,
            n53_2: 0,
            n54_2: 0,
            n55_2: 0,
            n56_2: 0,
            n57_2: 0,
            n58_2: 0,
            n59_2: 0,
            n60_2: 0,
            n61_2: 0,
            n62_2: 0,
            n63_2: 0,

            n33_3: 0,
            n34_3: 0,
            n35_3: 0,
            n36_3: 0,
            n37_3: 0,
            n38_3: 0,
            n39_3: 0,
            n40_3: 0,
            n41_3: 0,
            n42_3: 0,
            n43_3: 0,
            n44_3: 0,
            n45_3: 0,
            n46_3: 0,
            n47_3: 0,
            n48_3: 0,
            n49_3: 0,
            n50_3: 0,
            n51_3: 0,
            n52_3: 0,
            n53_3: 0,
            n54_3: 0,
            n55_3: 0,
            n56_3: 0,
            n57_3: 0,
            n58_3: 0,
            n59_3: 0,
            n60_3: 0,
            n61_3: 0,
            n62_3: 0,
            n63_3: 0,

            y1: 0,
            y2: 0,
            y3: 0,
            y4: 0,
            y5: 0,
            y6: 0,
            y7: 0,
            y8: 0,
            y9: 0,
            y10: 0,
            y11: 0,
            y12: 0,
            y13: 0,
            y14: 0,
            y15: 0,
            y16: 0,
            y17: 0,
            y18: 0,
            y19: 0,
            y20: 0,
            y21: 0,
            y22: 0,
            y23: 0,
            y24: 0,
            y25: 0,
            y26: 0,
            y27: 0,
            y28: 0,
            y29: 0,
            y30: 0,
            y31: 0,
            y32: 0,
            y33: 0,
            y34: 0,
            y35: 0,
            y36: 0,
            y37: 0,
            y38: 0,
            y39: 0,
            y40: 0,
            y41: 0,
            y42: 0,
            y43: 0,
            y44: 0,
            y45: 0,
            y46: 0,
            y47: 0,
            y48: 0,
            y49: 0,
            y50: 0,
            y51: 0,
            y52: 0,
            y53: 0,
            y54: 0,
            y55: 0,
            y56: 0,
        }

    },
    computed: {
        years() {
            const startYear = this.currentYear - 124;
            const endYear = this.currentYear;
            let yearsArray = [];
            for (let i = startYear; i <= endYear; i++) {
                yearsArray.push(i);
            }
            console.log(yearsArray)
            return yearsArray;
        }
    },
    methods: {
        changeToPers() {
            this.isPersonal = true;
            this.isCompatibility = false;
        },
        changeToComp() {
            this.isCompatibility = true;
            this.isPersonal = false;
        },
        IfIsMoreThan22(value) {
            if (value > 22) {
                this.values = value.toString().split('').map(Number);
                value = this.values.reduce((a, b) => b + a);

                if (value < 22) {
                    return value;
                } else {

                    if (value == 22) {
                        return value
                    } else {
                        this.values = value.toString().split('').map(Number);
                        value = this.values.reduce((a, b) => b + a);
                        return value;
                    }

                }

            } else {
                return value;
            }
        },
        TheN1() {
            this.n1 = this.IfIsMoreThan22(this.day);
        },

        TheN5() {
            this.n5 = this.IfIsMoreThan22(this.year);
        },

        TheN3() {
            this.n3 = this.IfIsMoreThan22(this.month);
        },


        TheN7() {
            this.n7 = this.IfIsMoreThan22(this.n1 + this.n5 + this.n3);
        },

        TheD() {
            this.n30 = this.IfIsMoreThan22(this.n1 + this.n5 + this.n3 + this.n7);
        },
        TheN17() {

            this.n17 = this.IfIsMoreThan22(this.n30 + this.n1);
        },
        TheN19() {
            this.n19 = this.IfIsMoreThan22(this.n3 + this.n30);
        },

        TheN21() {
            this.n21 = this.IfIsMoreThan22(this.n5 + this.n30);
        },

        TheN23() {
            this.n23 = this.IfIsMoreThan22(this.n7 + this.n30);
        },

        TheN9() {
            this.n9 = this.IfIsMoreThan22(this.n1 + this.n17);
        },

        TheN11() {
            this.n11 = this.IfIsMoreThan22(this.n19 + this.n3);
        },
        TheN13() {
            this.n13 = this.IfIsMoreThan22(this.n5 + this.n21);
        },

        TheN15() {
            this.n15 = this.IfIsMoreThan22(this.n7 + this.n23);
        },

        TheN2() {
            this.n2 = this.IfIsMoreThan22(this.n1 + this.n3);
        },

        TheN4() {
            this.n4 = this.IfIsMoreThan22(this.n5 + this.n3);
        },

        TheN6() {
            this.n6 = this.IfIsMoreThan22(this.n5 + this.n7);
        },

        TheN8() {
            this.n8 = this.IfIsMoreThan22(this.n1 + this.n7);
        },

        TheN18() {
            this.n18 = this.IfIsMoreThan22(this.n2 + this.n30);
        },

        TheN20() {
            this.n20 = this.IfIsMoreThan22(this.n4 + this.n30);
        },

        TheN22() {
            this.n22 = this.IfIsMoreThan22(this.n6 + this.n30);
        },

        TheN24() {
            this.n24 = this.IfIsMoreThan22(this.n8 + this.n30);
        },

        TheN10() {
            this.n10 = this.IfIsMoreThan22(this.n2 + this.n18);
        },

        TheN12() {
            this.n12 = this.IfIsMoreThan22(this.n4 + this.n20);
        },

        TheN14() {
            this.n14 = this.IfIsMoreThan22(this.n6 + this.n22);
        },

        TheN16() {
            this.n16 = this.IfIsMoreThan22(this.n8 + this.n24);
        },

        TheN26() {
            this.n26 = this.IfIsMoreThan22(this.n19 + this.n30);
        },

        TheN25() {
            this.n25 = this.IfIsMoreThan22(this.n17 + this.n30);
        },

        TheN28() {
            this.n28 = this.IfIsMoreThan22(this.n23 + this.n21);
        },

        TheN27() {
            this.n27 = this.IfIsMoreThan22(this.n28 + this.n21);
        },

        TheN29() {
            this.n29 = this.IfIsMoreThan22(this.n28 + this.n23);
        },

        TheN31() {
            this.n31 = this.IfIsMoreThan22(this.n2 + this.n4 + this.n6 + this.n8);
        },

        TheN32() {
            this.n32 = this.IfIsMoreThan22(this.n30 + this.n31);
        },



        TheN33() {
            this.n33 = this.n1;
        },

        TheN34() {
            this.n34 = this.n3;
        },

        TheN35() {
            this.n35 = this.IfIsMoreThan22(this.n3 + this.n1);
        },

        TheN36() {
            this.n36 = this.n9;
        },

        TheN37() {
            this.n37 = this.n11;
        },

        TheN38() {
            this.n38 = this.IfIsMoreThan22(this.n9 + this.n11);
        },

        TheN39() {
            this.n39 = this.n17;
        },

        TheN40() {
            this.n40 = this.n19;
        },

        TheN41() {
            this.n41 = this.IfIsMoreThan22(this.n19 + this.n17);
        },

        TheN42() {
            this.n42 = this.n25;
        },

        TheN43() {
            this.n43 = this.n26;
        },

        TheN44() {
            this.n44 = this.IfIsMoreThan22(this.n26 + this.n25);
        },

        TheN48() {
            this.n48 = this.n21;
        },

        TheN49() {
            this.n49 = this.n23;
        },



        TheN33_2() {
            this.n33_2 = this.n1_2;
        },

        TheN34_2() {
            this.n34_2 = this.n3_2;
        },

        TheN35_2() {
            this.n35_2 = this.IfIsMoreThan22(this.n3_2 + this.n1_2);
        },

        TheN36_2() {
            this.n36_2 = this.n9_2;
        },

        TheN37_2() {
            this.n37_2 = this.n11_2;
        },

        TheN38_2() {
            this.n38_2 = this.IfIsMoreThan22(this.n9_2 + this.n11_2);
        },

        TheN39_2() {
            this.n39_2 = this.n17_2;
        },

        TheN40_2() {
            this.n40_2 = this.n19_2;
        },

        TheN41_2() {
            this.n41_2 = this.IfIsMoreThan22(this.n19_2 + this.n17_2);
        },

        TheN42_2() {
            this.n42_2 = this.n25_2;
        },

        TheN43_2() {
            this.n43_2 = this.n26_2;
        },

        TheN44_2() {
            this.n44_2 = this.IfIsMoreThan22(this.n26_2 + this.n25_2);
        },

        TheN48_2() {
            this.n48_2 = this.n21_2;
        },

        TheN49_2() {
            this.n49_2 = this.n23_2;
        },




        TheN33_3() {
            this.n33_3 = this.n1_3;
        },

        TheN34_3() {
            this.n34_3 = this.n3_3;
        },

        TheN35_3() {
            this.n35_3 = this.IfIsMoreThan22(this.n3_3 + this.n1_3);
        },

        TheN36_3() {
            this.n36_3 = this.n9_3;
        },

        TheN37_3() {
            this.n37_3 = this.n11_3;
        },

        TheN38_3() {
            this.n38_3 = this.IfIsMoreThan22(this.n9_3 + this.n11_3);
        },

        TheN39_3() {
            this.n39_3 = this.n17_3;
        },

        TheN40_3() {
            this.n40_3 = this.n19_3;
        },

        TheN41_3() {
            this.n41_3 = this.IfIsMoreThan22(this.n19_3 + this.n17_3);
        },

        TheN42_3() {
            this.n42_3 = this.n25_3;
        },

        TheN43_3() {
            this.n43_3 = this.n26_3;
        },

        TheN44_3() {
            this.n44_3 = this.IfIsMoreThan22(this.n26_3 + this.n25_3);
        },

        TheN48_3() {
            this.n48_3 = this.n21_3;
        },

        TheN49_3() {
            this.n49_3 = this.n15_3;
        },





        TheN1_2() {
            this.n1_2 = this.IfIsMoreThan22(this.day_2);
        },

        TheN5_2() {
            this.n5_2 = this.IfIsMoreThan22(this.year_2);
        },

        TheN3_2() {
            this.n3_2 = this.IfIsMoreThan22(this.month_2);
        },


        TheN7_2() {
            this.n7_2 = this.IfIsMoreThan22(this.n1_2 + this.n5_2 + this.n3_2);
        },

        TheD_2() {
            this.n30_2 = this.IfIsMoreThan22(this.n1_2 + this.n5_2 + this.n3_2 + this.n7_2);
        },
        TheN17_2() {

            this.n17_2 = this.IfIsMoreThan22(this.n30_2 + this.n1_2);
        },
        TheN19_2() {
            this.n19_2 = this.IfIsMoreThan22(this.n3_2 + this.n30_2);
        },

        TheN21_2() {
            this.n21_2 = this.IfIsMoreThan22(this.n5_2 + this.n30_2);
        },

        TheN23_2() {
            this.n23_2 = this.IfIsMoreThan22(this.n7_2 + this.n30_2);
        },

        TheN9_2() {
            this.n9_2 = this.IfIsMoreThan22(this.n1_2 + this.n17_2);
        },

        TheN11_2() {
            this.n11_2 = this.IfIsMoreThan22(this.n19_2 + this.n3_2);
        },
        TheN13_2() {
            this.n13_2 = this.IfIsMoreThan22(this.n5_2 + this.n21_2);
        },

        TheN15_2() {
            this.n15_2 = this.IfIsMoreThan22(this.n7_2 + this.n23_2);
        },

        TheN2_2() {
            this.n2_2 = this.IfIsMoreThan22(this.n1_2 + this.n3_2);
        },

        TheN4_2() {
            this.n4_2 = this.IfIsMoreThan22(this.n5_2 + this.n3_2);
        },

        TheN6_2() {
            this.n6_2 = this.IfIsMoreThan22(this.n5_2 + this.n7_2);
        },

        TheN8_2() {
            this.n8_2 = this.IfIsMoreThan22(this.n1_2 + this.n7_2);
        },

        TheN18_2() {
            this.n18_2 = this.IfIsMoreThan22(this.n2_2 + this.n30_2);
        },

        TheN20_2() {
            this.n20_2 = this.IfIsMoreThan22(this.n4_2 + this.n30_2);
        },

        TheN22_2() {
            this.n22_2 = this.IfIsMoreThan22(this.n6_2 + this.n30_2);
        },

        TheN24_2() {
            this.n24_2 = this.IfIsMoreThan22(this.n8_2 + this.n30_2);
        },

        TheN10_2() {
            this.n10_2 = this.IfIsMoreThan22(this.n2_2 + this.n18_2);
        },

        TheN12_2() {
            this.n12_2 = this.IfIsMoreThan22(this.n4_2 + this.n20_2);
        },

        TheN14_2() {
            this.n14_2 = this.IfIsMoreThan22(this.n6_2 + this.n22_2);
        },

        TheN16_2() {
            this.n16_2 = this.IfIsMoreThan22(this.n8_2 + this.n24_2);
        },

        TheN26_2() {
            this.n26_2 = this.IfIsMoreThan22(this.n19_2 + this.n30_2);
        },

        TheN26_3() {
            this.n26_3 = this.IfIsMoreThan22(this.n19_3 + this.n30_3);
        },

        TheN25_2() {
            this.n25_2 = this.IfIsMoreThan22(this.n17_2 + this.n30_2);
        },

        TheN25_3() {
            this.n25_3 = this.IfIsMoreThan22(this.n17_3 + this.n30_3);
        },



        TheN28_2() {
            this.n28_2 = this.IfIsMoreThan22(this.n23_2 + this.n21_2);
        },

        TheN27_2() {
            this.n27_2 = this.IfIsMoreThan22(this.n28_2 + this.n21_2);
        },

        TheN29_2() {
            this.n29_2 = this.IfIsMoreThan22(this.n28_2 + this.n23_2);
        },

        TheN31_2() {
            this.n31_2 = this.IfIsMoreThan22(this.n2_2 + this.n4_2 + this.n6_2 + this.n8_2);
        },

        TheN32_2() {
            this.n32_2 = this.IfIsMoreThan22(this.n30_2 + this.n31_2);
        },


        TheN45() {
            this.n45 = this.n30;
        },

        TheN45_2() {
            this.n45_2 = this.n30_2;
        },

        TheN45_3() {
            this.n45_3 = this.n30_3;
        },

        TheN46() {
            this.n46 = this.n30;
        },

        TheN46_2() {
            this.n46_2 = this.n30_2;
        },

        TheN46_3() {
            this.n46_3 = this.n30_3;
        },

        TheN47() {
            this.n47 = this.IfIsMoreThan22(this.n30 + this.n30);
        },

        TheN47_2() {
            this.n47_2 = this.IfIsMoreThan22(this.n30_2 + this.n30_2);
        },

        TheN50() {
            this.n50 = this.IfIsMoreThan22(this.n21 + this.n23);
        },

        TheN47_3() {
            this.n47_3 = this.IfIsMoreThan22(this.n30_3 + this.n30_3);
        },

        TheN50_3() {
            this.n50_3 = this.IfIsMoreThan22(this.n21_3 + this.n15_3);
        },


        TheN51() {
            this.n51 = this.n5;
        },

        TheN51_3() {
            this.n51_3 = this.n5_3;
        },

        TheN52() {
            this.n52 = this.n7;
        },

        TheN53() {
            this.n53 = this.IfIsMoreThan22(this.n5 + this.n7);
        },

        TheN52_3() {
            this.n52_3 = this.n7_3;
        },



        TheN53_3() {
            this.n53_3 = this.IfIsMoreThan22(this.n5_3 + this.n7_3);
        },

        TheN54() {
            this.n54 = this.IfIsMoreThan22(this.n1 + this.n9 + this.n17 + this.n25 + this.n30 + this.n21 + this.n5);
        },
        TheN54_3() {
            this.n54_3 = this.IfIsMoreThan22(this.n1_3 + this.n9_3 + this.n17_3 + this.n25_3 + this.n30_3 + this.n21_3 + this.n5_3);
        },

        TheN55() {
            this.n55 = this.IfIsMoreThan22(this.n3 + this.n11 + this.n19 + this.n26 + this.n30 + this.n23 + this.n7);
        },

        TheN55_3() {
            this.n55_3 = this.IfIsMoreThan22(this.n3_3 + this.n11_3 + this.n19_3 + this.n26_3 + this.n30_3 + this.n15_3 + this.n7_3);
        },

        TheN56() {
            this.n56 = this.IfIsMoreThan22(this.n35 + this.n38 + this.n41 + this.n44 + this.n47 + this.n50 + this.n53);
        },

        TheN56_3() {
            this.n56_3 = this.IfIsMoreThan22(this.n35_3 + this.n38_3 + this.n41_3 + this.n44_3 + this.n47_3 + this.n50_3 + this.n53_3);
        },

        TheN57() {
            this.n57 = this.IfIsMoreThan22(this.n3 + this.n7);
        },

        TheN57_3() {
            this.n57_3 = this.IfIsMoreThan22(this.n3_3 + this.n7_3);
        },


        TheN59() {
            this.n59 = this.IfIsMoreThan22(this.n1 + this.n5);
        },

        TheN59_3() {
            this.n59_3 = this.IfIsMoreThan22(this.n1_3 + this.n5_3);
        },

        TheN58() {
            this.n58 = this.IfIsMoreThan22(this.n59 + this.n57);
        },

        TheN58_3() {
            this.n58_3 = this.IfIsMoreThan22(this.n59_3 + this.n57_3);
        },


        TheN60() {
            this.n60 = this.IfIsMoreThan22(this.n2 + this.n6);
        },

        TheN60_3() {
            this.n60_3 = this.IfIsMoreThan22(this.n2_3 + this.n6_3);
        },


        TheN62() {
            this.n62 = this.IfIsMoreThan22(this.n4 + this.n8);
        },

        TheN62_3() {
            this.n62_3 = this.IfIsMoreThan22(this.n4_3 + this.n8_3);
        },

        TheN61() {
            this.n61 = this.IfIsMoreThan22(this.n60 + this.n62);
        },

        TheN61_3() {
            this.n61_3 = this.IfIsMoreThan22(this.n60_3 + this.n62_3);
        },


        TheN63() {
            this.n63 = this.IfIsMoreThan22(this.n58 + this.n61);
        },

        TheN63_3() {
            this.n63_3 = this.IfIsMoreThan22(this.n58_3 + this.n61_3);
        },

        //calculating years

        TheY1() {
            this.y1 = this.IfIsMoreThan22(this.n1 + this.n2);
        },

        TheY2() {
            this.y2 = this.IfIsMoreThan22(this.n1 + this.y1);
        },

        TheY3() {
            this.y3 = this.IfIsMoreThan22(this.n1 + this.y2);
        },

        TheY4() {
            this.y4 = this.IfIsMoreThan22(this.y1 + this.y2);
        },

        TheY5() {
            this.y5 = this.IfIsMoreThan22(this.y1 + this.n2);
        },

        TheY7() {
            this.y7 = this.IfIsMoreThan22(this.y1 + this.y5);
        },

        TheY6() {
            this.y6 = this.IfIsMoreThan22(this.y5 + this.n2);
        },

        TheY8() {
            this.y8 = this.IfIsMoreThan22(this.n3 + this.n2);
        },

        TheY9() {
            this.y9 = this.IfIsMoreThan22(this.y8 + this.n2);
        },

        TheY10() {
            this.y10 = this.IfIsMoreThan22(this.y9 + this.n2);
        },

        TheY11() {
            this.y11 = this.IfIsMoreThan22(this.y9 + this.y8);
        },

        TheY12() {
            this.y12 = this.IfIsMoreThan22(this.n3 + this.y8);
        },

        TheY13() {
            this.y13 = this.IfIsMoreThan22(this.month + this.y12);
        },

        TheY14() {
            this.y14 = this.IfIsMoreThan22(this.y8 + this.y12);
        },

        TheY15() {
            this.y15 = this.IfIsMoreThan22(this.month + this.n4);
        },

        TheY16() {
            this.y16 = this.IfIsMoreThan22(this.month + this.y15);
        },

        TheY17() {
            this.y17 = this.IfIsMoreThan22(this.month + this.y16);
        },

        TheY18() {
            this.y18 = this.IfIsMoreThan22(this.y15 + this.y16);
        },

        TheY19() {
            this.y19 = this.IfIsMoreThan22(this.y15 + this.n4);
        },

        TheY20() {
            this.y20 = this.IfIsMoreThan22(this.y15 + this.y19);
        },

        TheY21() {
            this.y21 = this.IfIsMoreThan22(this.n4 + this.y19);
        },

        TheY22() {
            this.y22 = this.IfIsMoreThan22(this.n5 + this.n4);
        },

        TheY23() {
            this.y23 = this.IfIsMoreThan22(this.y22 + this.n4);
        },

        TheY24() {
            this.y24 = this.IfIsMoreThan22(this.y23 + this.n4);
        },

        TheY25() {
            this.y25 = this.IfIsMoreThan22(this.y23 + this.y22);
        },

        TheY26() {
            this.y26 = this.IfIsMoreThan22(this.n5 + this.y22);
        },

        TheY27() {
            this.y27 = this.IfIsMoreThan22(this.n5 + this.y26);
        },

        TheY28() {
            this.y28 = this.IfIsMoreThan22(this.y22 + this.y26);
        },

        TheY29() {
            this.y29 = this.IfIsMoreThan22(this.n6 + this.n5);
        },

        TheY30() {
            this.y30 = this.IfIsMoreThan22(this.y29 + this.n5);
        },

        TheY31() {
            this.y31 = this.IfIsMoreThan22(this.y29 + this.y30);
        },

        TheY32() {
            this.y32 = this.IfIsMoreThan22(this.n5 + this.y30);
        },

        TheY33() {
            this.y33 = this.IfIsMoreThan22(this.n6 + this.y29);
        },

        TheY34() {
            this.y34 = this.IfIsMoreThan22(this.n6 + this.y33);
        },

        TheY35() {
            this.y35 = this.IfIsMoreThan22(this.y29 + this.y33);
        },

        TheY36() {
            this.y36 = this.IfIsMoreThan22(this.n6 + this.n7);
        },

        TheY37() {
            this.y37 = this.IfIsMoreThan22(this.n6 + this.y36);
        },

        TheY38() {
            this.y38 = this.IfIsMoreThan22(this.n6 + this.y37);
        },

        TheY39() {
            this.y39 = this.IfIsMoreThan22(this.y36 + this.y37);
        },

        TheY40() {
            this.y40 = this.IfIsMoreThan22(this.y36 + this.n7);
        },

        TheY41() {
            this.y41 = this.IfIsMoreThan22(this.y36 + this.y40);
        },

        TheY42() {
            this.y42 = this.IfIsMoreThan22(this.n7 + this.y40);
        },

        TheY43() {
            this.y43 = this.IfIsMoreThan22(this.n7 + this.n8);
        },

        TheY44() {
            this.y44 = this.IfIsMoreThan22(this.n7 + this.y43);
        },

        TheY45() {
            this.y45 = this.IfIsMoreThan22(this.n7 + this.y44);
        },

        TheY46() {
            this.y46 = this.IfIsMoreThan22(this.y43 + this.y44);
        },

        TheY47() {
            this.y47 = this.IfIsMoreThan22(this.y43 + this.n8);
        },

        TheY48() {
            this.y48 = this.IfIsMoreThan22(this.y43 + this.y47);
        },

        TheY49() {
            this.y49 = this.IfIsMoreThan22(this.n8 + this.y47);
        },

        TheY50() {
            this.y50 = this.IfIsMoreThan22(this.n8 + this.n1);
        },

        TheY51() {
            this.y51 = this.IfIsMoreThan22(this.n8 + this.y50);
        },

        TheY52() {
            this.y52 = this.IfIsMoreThan22(this.n8 + this.y51);
        },

        TheY53() {
            this.y53 = this.IfIsMoreThan22(this.y50 + this.y51);
        },

        TheY54() {
            this.y54 = this.IfIsMoreThan22(this.y50 + this.n1);
        },

        TheY55() {
            this.y55 = this.IfIsMoreThan22(this.n1 + this.y54);
        },

        TheY56() {
            this.y56 = this.IfIsMoreThan22(this.y50 + this.y54);
        },

        TheBoth() {
            this.n1_3 = this.IfIsMoreThan22(this.n1 + this.n1_2);
            this.n2_3 = this.IfIsMoreThan22(this.n2 + this.n2_2);
            this.n3_3 = this.IfIsMoreThan22(this.n3 + this.n3_2);
            this.n4_3 = this.IfIsMoreThan22(this.n4 + this.n4_2);
            this.n5_3 = this.IfIsMoreThan22(this.n5 + this.n5_2);
            this.n6_3 = this.IfIsMoreThan22(this.n6 + this.n6_2);
            this.n7_3 = this.IfIsMoreThan22(this.n7 + this.n7_2);
            this.n8_3 = this.IfIsMoreThan22(this.n8 + this.n8_2);
            this.n30_3 = this.IfIsMoreThan22(this.n30 + this.n30_2);
            this.n17_3 = this.IfIsMoreThan22(this.n1_3 + this.n30_3);
            this.n9_3 = this.IfIsMoreThan22(this.n17_3 + this.n1_3);
            this.n19_3 = this.IfIsMoreThan22(this.n3_3 + this.n30_3);
            this.n11_3 = this.IfIsMoreThan22(this.n19_3 + this.n3_3);
            this.n21_3 = this.IfIsMoreThan22(this.n5_3 + this.n30_3);
            this.n13_3 = this.IfIsMoreThan22(this.n21_3 + this.n5_3);
            this.n15_3 = this.IfIsMoreThan22(this.n7_3 + this.n30_3);
            this.n23_3 = this.IfIsMoreThan22(this.n15_3 + this.n7_3);
            this.n28_3 = this.IfIsMoreThan22(this.n21_3 + this.n15_3);
            this.n29_3 = this.IfIsMoreThan22(this.n28_3 + this.n15_3);
            this.n27_3 = this.IfIsMoreThan22(this.n21_3 + this.n28_3);


        },




        Calculat() {
            this.calcCount++;
            this.TheN1();
            this.TheN5();
            this.TheN3();
            this.TheN7();
            this.TheD();
            this.TheN17();
            this.TheN19();
            this.TheN41();
            this.TheN21();
            this.TheN23();
            this.TheN9();
            this.TheN11();
            this.TheN13();
            this.TheN2();
            this.TheN15();
            this.TheN4();
            this.TheN6();
            this.TheN8();
            this.TheN18();
            this.TheN20();
            this.TheN22();
            this.TheN24();
            this.TheN10();
            this.TheN12();
            this.TheN14();
            this.TheN16();
            this.TheN26();
            this.TheN25();
            this.TheN28();
            this.TheN27();
            this.TheN29();
            this.TheN31();
            this.TheN32();
            this.TheN33();
            this.TheN34();
            this.TheN35();
            this.TheN36();
            this.TheN37();
            this.TheN38();
            this.TheN39();
            this.TheN40();
            this.TheN41();
            this.TheN42();
            this.TheN43();
            this.TheN44();
            this.TheN45();
            this.TheN46();
            this.TheN47();
            this.TheN48();
            this.TheN49();

            //Years
            this.TheY1();
            this.TheY2();
            this.TheY3();
            this.TheY4();
            this.TheY5();
            this.TheY7();
            this.TheY6();
            this.TheY8();
            this.TheY9();
            this.TheY10();
            this.TheY11();
            this.TheY12();
            this.TheY13();
            this.TheY14();
            this.TheY15();
            this.TheY16();
            this.TheY17();
            this.TheY18();
            this.TheY19();
            this.TheY20();
            this.TheY21();
            this.TheY22();
            this.TheY23();
            this.TheY24();
            this.TheY25();
            this.TheY26();
            this.TheY27();
            this.TheY28();
            this.TheY29();
            this.TheY30();
            this.TheY31();
            this.TheY32();
            this.TheY33();
            this.TheY34();
            this.TheY35();
            this.TheY36();
            this.TheY37();
            this.TheY38();
            this.TheY39();
            this.TheY40();
            this.TheY41();
            this.TheY42();
            this.TheY43();
            this.TheY44();
            this.TheY45();
            this.TheY46();
            this.TheY47();
            this.TheY48();
            this.TheY49();
            this.TheY50();
            this.TheY51();
            this.TheY52();
            this.TheY53();
            this.TheY54();
            this.TheY55();
            this.TheY56();

            // Second date 
            this.TheN1_2();
            this.TheN5_2();
            this.TheN3_2();
            this.TheN7_2();
            this.TheD_2();
            this.TheN17_2();
            this.TheN19_2();

            this.TheN21_2();
            this.TheN23_2();
            this.TheN9_2();
            this.TheN11_2();
            this.TheN13_2();
            this.TheN2_2();
            this.TheN15_2();
            this.TheN4_2();
            this.TheN6_2();
            this.TheN8_2();
            this.TheN18_2();
            this.TheN20_2();
            this.TheN22_2();
            this.TheN24_2();
            this.TheN10_2();
            this.TheN12_2();
            this.TheN14_2();
            this.TheN16_2();
            this.TheN26_2();

            this.TheN25_2();

            this.TheN28_2();
            this.TheN27_2();
            this.TheN29_2();
            this.TheN31_2();
            this.TheN32_2();
            this.TheBoth();

            this.TheN25_3();
            this.TheN26_3();

            this.TheN33_2();
            this.TheN34_2();
            this.TheN35_2();
            this.TheN36_2();
            this.TheN37_2();
            this.TheN38_2();
            this.TheN39_2();
            this.TheN40_2();
            this.TheN41_2();
            this.TheN42_2();
            this.TheN43_2();
            this.TheN44_2();
            this.TheN45_2();
            this.TheN46_2();
            this.TheN47_2();
            this.TheN48_2();
            this.TheN49_2();

            this.TheN33_3();
            this.TheN34_3();
            this.TheN35_3();
            this.TheN36_3();
            this.TheN37_3();
            this.TheN38_2();
            this.TheN38_3();
            this.TheN39_3();
            this.TheN40_3();
            this.TheN41_3();

            this.TheN45_3();
            this.TheN46_3();
            this.TheN47_3();
            this.TheN48_3();
            this.TheN49_3();

            this.TheN50();
            this.TheN50_3();
            this.TheN51();
            this.TheN52();
            this.TheN53();
            this.TheN51_3();
            this.TheN52_3();
            this.TheN53_3();
            this.TheN54();

            this.TheN55();

            this.TheN56();
            this.TheN44_3();
            this.TheN56_3();
            this.TheN57();
            this.TheN57_3();
            this.TheN59();
            this.TheN59_3();
            this.TheN58();
            this.TheN58_3();
            this.TheN60();
            this.TheN60_3();
            this.TheN62();
            this.TheN62_3();
            this.TheN61();

            this.TheN63();

            this.TheN61_3();
            this.TheN63_3();
            this.TheN42_3();
            this.TheN43_3();

            this.TheN55_3();
            this.TheN54_3();
            
            if(this.calcCount > 1 && this.name === "") this.name = "Incognito persona";
        },

    },
    beforeMount() {
        this.Calculat();
    },

}
</script>

<style scoped>
.resultItem {
    background: #fafbfc;
}

.sectionopener items-center span {
    font-size: 24px;
    font-weight: bold;
}

.info {
    border-radius: 8px;
    top: -45px;
    left: 50%;
    transform: translate(-50%);
    display: none;
    z-index: 1000;
    transition: 300ms;
    background: #E9F0F7;
    border: 2px solid;
}

.info:after {
    z-index: 99;
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: #E9F0F7;
    transform: rotate(-45deg) translateX(-50%);
    bottom: -3px;
    left: 50%;
    transition: 300ms;
    border-bottom: 1px solid;
    border-left: 1px solid;
}



div,
p,
span {
    font-family: 'Lato', sans-serif;
}

.transimage {
    bottom: 2px !important;
    right: 1px !important;
}

@media screen and (max-width:1200px) {
    .obch {
        flex: 0 0 100% !important;
        padding: 10px;
    }
}


#formMatrix h3 {
    text-align: center;
}


.obch .trans,
.obch .trans>div {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.obch .trans span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
}

.birthdate {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 50px;
    justify-content: center;
}

.birthdate-item {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.transimage {
    width: 100%;
    z-index: 0;
    position: relative;

}

button.active {
    background: orange;
}

.result p {
    margin-bottom: 18px;
}

.trans span {
    font-size: 13px;
}

.chart-title {
    font-family: 'DM Serif Display', serif;
    text-align: center;
    font-size: 36px;
}

@media screen and (max-width:1335px) {
    .trans span {
        font-size: 12px;
    }

    .container {
        width: 1280px;
        max-width: 100%;
        margin: 0 auto;
    }


    body {
        font-size: 12px !important
    }
}

@media screen and (max-width:1200px) {
    .obch {
        flex: 0 0 100%;
        padding: 2px;
    }

    .rightblock {
        flex: 0 0 100%;
    }

    .trans span {
        font-size: 14px;
    }



}

#n1 span,
#n3 span,
#n5 span,
#n7 span,
#n30 span,
#n2 span,
#n4 span,
#n6 span,
#n8 span {
    font-size: 20px
}

.table {
    max-width: 550px;
}

@media screen and (max-width:600px) {
    .matrix-form-tabs button {
        padding: 10px 12px;
        width: 100%;
    }

    .table {
        max-width: 100%;
    }

    .chakras>div {
        padding-left: 20px;
    }

    .trans span {
        font-size: 12px;
        position: relative;

    }

    .obch .trans span {
        font-size: 9px;

    }

    td {
        font-size: 13px !important;
    }

    #n1 span,
    #n3 span,
    #n5 span,
    #n7 span,
    #n30 span,
    #n2 span,
    #n4 span,
    #n6 span,
    #n8 span {
        font-size: 16px;
        position: relative;
        bottom: 1px;
        right: 2%;
    }
    
    .transimage {
        width: 100%;
        z-index: 0;
        position: relative;
        right: 1px;
    }

    table {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin: 0 auto;
    }



}

table .trans {
    text-align: center;
}

@media screen and (max-width:470px) {

    .obch .trans span {
        font-size: 9px;


    }

}
</style>
